<template>
  <v-container fluid class="grey lighten-3">

    <v-card class="mb-5">

        <v-tabs v-model="tab" @change="tabChanged">
          <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item>
            <NotificationsStats v-if="tab == 0"></NotificationsStats>
          </v-tab-item>

          <v-tab-item>
            <NotificationsList v-if="tab == 1"></NotificationsList>
          </v-tab-item>

        </v-tabs-items>

    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'Notifications',

  data () {
    return {
      tab: null,
      items: ['Stats', 'List'],
      lsTab: 'notifications_tab',
    }
  },

  components: {
    NotificationsList: () => import('@/components/notifications/NotificationsList.vue'),
    NotificationsStats: () => import('@/components/notifications/NotificationsStats.vue'),
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }
  },
}
</script>

<style scoped>
</style>